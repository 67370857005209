import { createStore } from 'vuex'

import user from './user'
import toggl from './toggl'
import zohoInvoice from './zoho-invoice'
import gapi from './gapi'

export interface RootState {
  test?: string
}

export default createStore<RootState>({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    toggl,
    zohoInvoice,
    gapi
  }
})
