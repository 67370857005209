<template>
  <div class="flex">
    <div
      class="flex items-center w-full md:w-auto border-b pb-8 mb-8 pr-8 font-mono"
      :class="{ 'border-transparent': !isLoggedIn }"
    >
      <template v-if="isLoggedIn">
        <router-link to="/invoice">
          Invoice
        </router-link>
        <span class="text-gray-200 mx-3">|</span>
        <router-link to="/sync">
          Sync
        </router-link>
        <span class="text-gray-200 mx-3">|</span>
        <router-link
          to="/settings"
          class="flex"
        >
          <i class="material-icons">settings</i>
          <span class="sr-only">Settings</span>
        </router-link>
        <span class="text-gray-200 mx-3">|</span>
      </template>
      <login />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import Login from '@/components/Login.vue'

@Options({
  components: {
    Login
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    })
  }
})
export default class Navigation extends Vue {
  isLoggedIn!: boolean
}
</script>

<style lang="scss" scoped>
a {
  @apply border-b-2 border-transparent;

  &.router-link-active {
    @apply border-b-2 border-gray-600 font-bold;
  }

  @apply py-1 text-gray-800;
}
</style>
