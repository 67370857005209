
import { Options, Vue } from 'vue-class-component'
import { mapGetters, mapActions } from 'vuex'

@Options({
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    })
  },
  methods: {
    ...mapActions({
      login: 'user/loginWithGoogle',
      logout: 'user/logout'
    })
  }
})
export default class Login extends Vue {
  isLoggedIn!: boolean
}
