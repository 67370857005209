import config from 'config'
import { Module } from 'vuex'
import { stringifyUrl } from 'query-string'
import { usersCollection } from '@/firebase'
import { RootState } from '../'

export interface ZohoInvoiceProject {
  'project_id': number
  'project_name': string
  'customer_id': number
  'customer_name': string
  'description': string
  'status': 'active' | 'inactive'
  'total_hours': string
  'billable_hours': string
  [key: string]: any
}

export interface ZohoInvoiceTask {
  'task_id': number
  'task_name': string,
  'description': string
  'project_id': number
  'project_name': string
  'customer_id': number
  'customer_name': string
  'status': 'active' | 'inactive'
  'total_hours': string
  'billed_hours': string
  'is_billable': boolean
  'rate': number
  [key: string]: any
}

export interface ZohoInvoiceShort {
  'invoice_id': number
  'invoice_number': string
  'customer_id': number
  'customer_name': string
  'status': string
  [key: string]: any
}

export interface ZohoInvoiceLineItem {
  'line_item_id': number
  'item_id': number
  'project_id': number
  'name': string
  'description': string
  'rate': number
  'quantity': number
  'item_total': number
  [key: string]: any
}

export interface ZohoInvoice {
  'invoice_id': number
  'invoice_number': string
  'customer_id': number
  'customer_name': string
  'status': string
  'line_items': ZohoInvoiceLineItem[]
  'payment_terms': number
  [key: string]: any
}

export interface State {
  userId: number | null
  projects: ZohoInvoiceProject[]
  tasks: ZohoInvoiceTask[]
  invoiceList: ZohoInvoiceShort[],
  invoice: ZohoInvoice | null
}

interface ZohoInvoiceAPIRequestParams {
  endpoint: string
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  query?: Record<string, any>
  headers?: Record<string, any>
  body?: Record<string, any>,
  parseForm?: boolean
}

const module: Module<State, RootState> = {
  namespaced: true,
  state: {
    userId: null,
    projects: [],
    tasks: [],
    invoiceList: [],
    invoice: null
  },
  getters: {
    getConfig: (state, getters, rootState, rootGetters) => (key: string): string | boolean => {
      return rootGetters['user/getConfig'](key)
    },
    getUserId: (state): number | null => state.userId,
    getOrganisationId: (state, getters): number => getters.getConfig('zohoInvoiceOrganisationId'),
    getAccessToken: (state, getters): string => getters.getConfig('zohoInvoiceAccessToken'),
    getRefreshToken: (state, getters): string => getters.getConfig('zohoInvoiceRefreshToken'),
    getAccessTokenExpires: (state, getters): number => getters.getConfig('zohoInvoiceAccessTokenExpires'),
    isAccessTokenValid: (state, getters, rootState, rootGetters): boolean => {
      return rootGetters['user/loaded'] && Date.now() / 1000 <= getters.getAccessTokenExpires
    },
    getProjects: (state): ZohoInvoiceProject[] => state.projects,
    getTasks: (state): ZohoInvoiceTask[] => state.tasks,
    getInvoiceList: (state): ZohoInvoiceShort[] => state.invoiceList,
    getInvoice: (state): ZohoInvoice | null => state.invoice
  },
  mutations: {
    setUserId: (state, id: number) => {
      state.userId = id
    },
    setProjects: (state, projects: ZohoInvoiceProject[]) => {
      state.projects = projects
    },
    setTasks: (state, tasks: ZohoInvoiceTask[]) => {
      state.tasks = tasks
    },
    addTask: (state, task: ZohoInvoiceTask) => {
      state.tasks.push(task)
    },
    setInvoiceList: (state, list: ZohoInvoiceShort[]) => {
      state.invoiceList = list
    },
    setInvoice: (state, invoice: ZohoInvoice) => {
      state.invoice = invoice
    }
  },
  actions: {
    async fetch ({ getters, dispatch }, { endpoint, method = 'GET', query = {}, headers = {}, body, parseForm }: ZohoInvoiceAPIRequestParams): Promise<any> {
      const hasRefreshToken = await dispatch('refreshToken')
      if (!hasRefreshToken) return

      const url = `${config.api.baseUrl}/api/zoho-invoice`
      const accessToken: string = getters.getAccessToken

      headers = Object.assign({
        Accept: 'application/json',
        Authorization: `Zoho-oauthtoken ${accessToken}`,
        'X-com-zoho-invoice-organizationid': getters.getOrganisationId
      }, headers)

      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          method,
          endpoint,
          headers,
          query,
          ...(body ? { body } : {}),
          ...(parseForm ? { parseForm } : {})
        })
      }

      return fetch(url, options)
        .then(r => r.json())
        .catch(e => {
          console.error('Request to ZohoInvoice API failed:', e.message)
          return {}
        })
    },
    async refreshToken ({ getters, dispatch, rootGetters }): Promise<boolean> {
      if (getters.isAccessTokenValid) {
        return true
      }

      const refreshToken = getters.getRefreshToken
      if (!refreshToken) {
        return false
      }

      const url = `${config.api.baseUrl}/api/zoho-invoice/auth`
      const headers = { Accept: 'application/json' }
      const query = { refresh_token: refreshToken }

      return fetch(stringifyUrl({ url, query }), { headers })
        .then(r => r.json())
        .then(async r => {
          if (r.error) {
            throw Error(r.error)
          }

          const { access_token: zohoInvoiceAccessToken, expires_in: zohoInvoiceAccessTokenLifetime } = r
          const zohoInvoiceAccessTokenExpires = (Date.now() / 1000) + zohoInvoiceAccessTokenLifetime
          const data = { zohoInvoiceAccessToken, zohoInvoiceAccessTokenExpires }

          const uid = rootGetters['user/getUid']
          return usersCollection
            .doc(uid)
            .update(data)
            .then(() => dispatch('user/fetchUserProfile', null, { root: true }))
            .then(() => true)
        })
        .catch(e => {
          console.error('Can\'t refresh ZohoInvoice access-token:', e.message)
          return false
        })
    },
    async getUser ({ dispatch, getters, commit }): Promise<any> {
      if (getters.getUserId) return
      return dispatch(
        'fetch',
        { endpoint: 'users/me' } as ZohoInvoiceAPIRequestParams
      ).then(resp => {
        commit('setUserId', resp.user.user_id)
        return resp
      })
    },
    async getProjects ({ commit, dispatch, getters }): Promise<void> {
      if (getters.getProjects.length > 0) return
      return dispatch(
        'fetch',
        {
          endpoint: 'projects',
          query: { filter_by: 'Status.Active' }
        } as ZohoInvoiceAPIRequestParams
      ).then(resp => commit('setProjects', resp.projects))
    },
    async getTasks ({ commit, dispatch, getters }, projectId: number): Promise<void> {
      if (!projectId || !getters.getTasks || getters.getTasks.length > 0) return
      return dispatch(
        'fetch',
        { endpoint: `projects/${projectId}/tasks` } as ZohoInvoiceAPIRequestParams
      ).then(resp => commit('setTasks', resp.task))
    },
    async addTask ({ dispatch }, { projectId, taskName, rate = 0 }): Promise<void> {
      const options: ZohoInvoiceAPIRequestParams = {
        endpoint: `projects/${projectId}/tasks`,
        method: 'POST',
        parseForm: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: { task_name: taskName, rate }
      }

      return dispatch('fetch', options)
        .then(resp => resp)
    },
    async addTiming ({ dispatch, getters }, { projectId, taskId, date, duration, description }): Promise<void> {
      const options: ZohoInvoiceAPIRequestParams = {
        endpoint: 'projects/timeentries',
        method: 'POST',
        parseForm: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: {
          project_id: projectId,
          task_id: taskId,
          user_id: getters.getUserId,
          log_date: date,
          log_time: duration,
          notes: description,
          is_billable: true
        }
      }

      return dispatch('fetch', options)
        .then(resp => resp)
    },
    async getInvoiceList ({ commit, dispatch, getters }): Promise<void> {
      if (!getters.getInvoiceList || getters.getInvoiceList.length > 0) return
      return dispatch(
        'fetch',
        { endpoint: 'invoices' } as ZohoInvoiceAPIRequestParams
      ).then(resp => commit('setInvoiceList', resp.invoices))
    },
    async getInvoice ({ commit, dispatch, getters }, { invoiceId, reset = false }: { invoiceId: number, reset: boolean }): Promise<any | void> {
      if (!reset && getters.getInvoice) return

      commit('setInvoice', null)

      return dispatch(
        'fetch',
        { endpoint: `invoices/${invoiceId}` } as ZohoInvoiceAPIRequestParams
      ).then(resp => {
        commit('setInvoice', resp.invoice)
        return resp
      })
    }
  }
}

export default module
