interface clients { name: string, version: string }

const loadGapiInsideDOM = async function (initWith: any = {}, clients: clients[] = []): Promise<typeof gapi> {
  return new Promise((resolve) => {
    const element = document.getElementsByTagName('script')[0]
    const js = document.createElement('script')
    js.id = 'google-api-client'
    js.src = '//apis.google.com/js/client.js'
    js.async = true
    js.defer = true

    // eslint-disable-next-line no-unused-expressions
    element.parentNode?.insertBefore(js, element)
    js.onload = async () => {
      await new Promise<void>((resolve, reject): void => {
        gapi.load('client', async () => {
          await gapi.client.init(initWith)
            .then(resolve)
            .catch(reject)
        })
      })

      await Promise.all(clients.map(({ name, version }) => gapi.client.load(name, version)))

      resolve(window.gapi)
    }
  })
}

export default loadGapiInsideDOM
