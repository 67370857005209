<template>
  <a
    v-if="!isLoggedIn"
    href="#"
    @click.prevent="login"
  >Login</a>
  <a
    v-else
    href="#"
    class="flex"
    @click.prevent="logout"
  >
    <i class="material-icons">exit_to_app</i>
    <span class="sr-only">Logout</span>
  </a>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapGetters, mapActions } from 'vuex'

@Options({
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    })
  },
  methods: {
    ...mapActions({
      login: 'user/loginWithGoogle',
      logout: 'user/logout'
    })
  }
})
export default class Login extends Vue {
  isLoggedIn!: boolean
}
</script>
