/* eslint-disable camelcase */

import { Module } from 'vuex'
import { RootState } from '../'
import { getFormattedDate, getFormattedDateWithOffset } from '@/helpers/datetime'
import qs from 'query-string'

export interface TogglProject {
  id: number
  cid: number
  name: string
  rate: number
  [key: string]: any
}

export interface TogglClient {
  id: number
  wid: number
  name: string
  [key: string]: any
}

export interface TogglTag {
  id: number
  workspace_id: number
  name: string
  [key: string]: any
}

export interface TogglTimeEntry {
  project_id: number
  user_id: number
  username: string
  description: string
  tag_ids: number[]
  tags: string[]
  row_number: number
  time_entries: {
    id: number
    seconds: number
    start: string
    stop: string
    at: string
  }[]
  [key: string]: any
}

export interface State {
  projects: TogglProject[]
  clients: TogglClient[]
  tags: TogglTag[]
  timeEntries: TogglTimeEntry[]
}

const module: Module<State, RootState> = {
  namespaced: true,
  state: {
    projects: [],
    clients: [],
    tags: [],
    timeEntries: []
  },
  getters: {
    getConfig: (state, getters, rootState, rootGetters) => (key: string): string | boolean => {
      return rootGetters['user/getConfig'](key)
    },
    getAccessToken: (state, getters): string => getters.getConfig('togglAccessToken'),
    getWorkspaceId: (state, getters): string => getters.getConfig('togglWorkspaceId'),
    getProjects: (state): TogglProject[] => state.projects,
    getClients: (state): TogglClient[] => state.clients,
    getTags: (state): TogglTag[] => state.tags,
    getTimeEntries: (state): TogglTimeEntry[] => state.timeEntries
  },
  mutations: {
    setProjects: (state, projects: TogglProject[]) => {
      state.projects = projects
    },
    setClients: (state, clients: TogglClient[]) => {
      state.clients = clients
    },
    setTags: (state, clients: TogglTag[]) => {
      state.tags = clients
    },
    setTimeEntries: (state, timeEntries: TogglTimeEntry[]) => {
      state.timeEntries = timeEntries
    },
    addTimeEntries: (state, timeEntries: TogglTimeEntry[]) => {
      state.timeEntries.push(...timeEntries)
    }
  },
  actions: {
    async fetchApi ({ dispatch }, { endpoint, params = {} }): Promise<Record<string, any>> {
      let url = `https://api.track.toggl.com/api/v9/${endpoint}`
      url = qs.stringifyUrl({ url, query: params })
      return dispatch('fetch', { url })
    },
    async fetchReportsApi (
      { dispatch, getters },
      { endpoint = 'search/time_entries', body = {}, method = 'POST' }
    ): Promise<Record<string, any>> {
      const workspaceId: string = getters.getWorkspaceId
      const url = `https://api.track.toggl.com/reports/api/v3/workspace/${workspaceId}/${endpoint}`
      return dispatch('fetch', { url, body, method })
    },
    async fetch ({ getters }, { url, method = 'GET', body = undefined }): Promise<any> {
      const accessToken: string = getters.getAccessToken

      let headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Basic ' + btoa(`${accessToken}:api_token`)
      }

      if (body && method === 'POST') {
        headers = Object.assign(headers, { 'Content-Type': 'application/json' })
        body = JSON.stringify(body)
      }

      return fetch(url, {
        method,
        headers,
        body
      })
        .then(r => r.json())
        .catch(e => {
          console.error('Request to toggl.com API failed:', e.message)
          return {}
        })
    },
    async getProjects ({ dispatch, getters, commit }): Promise<void> {
      if (getters.getProjects.length > 0) return

      return dispatch('fetchApi', {
        endpoint: 'workspaces/' + getters.getWorkspaceId + '/projects',
        params: { active: true }
      }).then(projects => commit('setProjects', projects))
    },
    async getClients ({ dispatch, commit, getters }): Promise<void> {
      if (getters.getClients.length > 0) return
      return dispatch('fetchApi', { endpoint: 'workspaces/' + getters.getWorkspaceId + '/clients' })
        .then(clients => commit('setClients', clients))
    },
    async getTags ({ dispatch, commit, getters }): Promise<void> {
      if (getters.getTags.length > 0) return
      return dispatch('fetchApi', { endpoint: 'workspaces/' + getters.getWorkspaceId + '/tags' })
        .then(tags => commit('setTags', tags))
    },
    async getTimeEntries ({ dispatch, commit, getters }, { projectIds, firstRowNumber = 1, reset = false }: { projectIds: number[], firstRowNumber?: number, reset: boolean }): Promise<void> {
      if (projectIds.length === 0) return

      if (reset) {
        commit('setTimeEntries', [])
      }

      const body = {
        order_dir: 'asc',
        order_by: 'date',
        project_ids: projectIds,
        start_date: getFormattedDateWithOffset(100, 'YYYY-MM-DD'),
        end_date: getFormattedDate('YYYY-MM-DD'),
        rounding: 1,
        rounding_minutes: 5,
        grouped: false,
        billable: true,
        first_row_number: firstRowNumber
      }

      return dispatch('fetchReportsApi', { endpoint: 'search/time_entries', method: 'POST', body })
        .then(response => {
          const timeEntries: TogglTimeEntry[] = response // eslint-disable-line camelcase

          timeEntries.map(e => {
            e.tags = e.tag_ids
              .map(id => (getters.getTags as TogglTag[]).find(t => t.id === id)?.name || id.toString())
            return e
          })

          commit('addTimeEntries', timeEntries)

          if (timeEntries.length === 50) {
            const lastEntry = timeEntries.slice(-1).pop()
            return dispatch('getTimeEntries', { projectIds, firstRowNumber: (lastEntry?.row_number || 0) + 1 })
          }
        })
    }
  }
}

export default module
