import Dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import customParseFormat from 'dayjs/plugin/customParseFormat'

Dayjs.extend(duration)
Dayjs.extend(customParseFormat)

export function parseDuration (seconds: number): string {
  const duration = Dayjs.duration(seconds, 'seconds')
  return duration.format('HH:mm')
}

export function parseTimeFromQty (hours: number): string {
  const duration = Dayjs.duration(hours, 'hours')
  const roundMinutes = 5 - (duration.get('minute') % 5)
  return duration.add(roundMinutes, 'm').format('HH:mm')
}

export function getDatetimeString (
  dateString: string,
  format = 'yyyy-MM-dd HH:mm:ss',
  inputFormat?: string | undefined
): string {
  return Dayjs(dateString, inputFormat).format(format)
}

export function getDateString (
  dateString: string,
  format = 'YYYY-MM-DD',
  inputFormat?: string | undefined
): string {
  return getDatetimeString(dateString, format, inputFormat)
}

export function getFormattedDate (format = 'YYYY-MM-DD HH:mm:ss:SSS'): string {
  return Dayjs().format(format)
}

export function getFormattedDateWithOffset (offset = 0, format = 'YYYY-MM-DD HH:mm:ss:SSS'): string {
  return Dayjs().subtract(offset, 'days').format(format)
}

export default {
  parseDuration,
  getDatetimeString,
  getDateString
}
