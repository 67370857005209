
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Settings extends Vue {
  created (): void {
    this.$emitter.on('onAuthStateApproved', this.onAuthStateApproved)
  }

  destroyed (): void {
    this.$emitter.off('onAuthStateApproved', this.onAuthStateApproved)
  }

  onAuthStateApproved (): void {
    this.$router.push('/invoice')
  }
}
