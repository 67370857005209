
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import Login from '@/components/Login.vue'

@Options({
  components: {
    Login
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    })
  }
})
export default class Navigation extends Vue {
  isLoggedIn!: boolean
}
