import { Module } from 'vuex'
import { RootState } from '..'
import { getFormattedDate, parseTimeFromQty } from '@/helpers/datetime'
import parseCurrency from '@/helpers/currency'

import { ZohoInvoiceLineItem } from '@/store/zoho-invoice'

export interface State {
  accessToken: string | null,
  accessTokenExpires: number | null,
  invoiceId: string | null,
  invoiceDocResponse: Record<string, any> | null
}

const module: Module<State, RootState> = {
  namespaced: true,
  state: {
    accessToken: null,
    accessTokenExpires: null,
    invoiceId: null,
    invoiceDocResponse: null
  },
  getters: {
    getConfig: (state, getters, rootState, rootGetters) => (key: string): string | boolean => {
      return rootGetters['user/getConfig'](key)
    },
    getAccessToken: (state, getters): number => getters.getConfig('gapiAccessToken'),
    getAccessTokenExpires: (state, getters): number => getters.getConfig('gapiAccessTokenExpires'),
    isAccessTokenValid: (state, getters, rootState, rootGetters): boolean => {
      return rootGetters['user/loaded'] && Date.now() / 1000 <= getters.getAccessTokenExpires
    },
    getInvoiceTemplateId: (state, getters): number => getters.getConfig('gapiInvoiceTemplateId'),
    getInvoiceId: (state): any => state.invoiceId,
    getInvoiceDocResponse: (state): any => state.invoiceDocResponse,
    getInvoiceDocItemsTable: (state): any => {
      const tables = (state.invoiceDocResponse as any).filter((i: any) => Object.keys(i).includes('table'))
      return tables[1]
    }
  },
  mutations: {
    setInvoiceId (state, invoiceId: string): void {
      state.invoiceId = invoiceId
    },
    setInvoiceDocResponse (state, invoice: any): void {
      state.invoiceDocResponse = invoice
    }
  },
  actions: {
    setAccessToken ({ getters }): void {
      if (!getters.getAccessToken || !getters.isAccessTokenValid) {
        throw Error('Access-token is invalid')
      }

      gapi.auth.setToken({
        access_token: getters.getAccessToken
      } as any)
    },
    duplicateInvoiceTemplate ({ dispatch, commit, getters }, zohoInvoiceNumber: string): Promise<any> {
      dispatch('setAccessToken')

      const currentDatetime = getFormattedDate('YYYYMMDD-HHmmss')

      return (gapi.client as any).drive.files
        .copy({
          fileId: getters.getInvoiceTemplateId,
          resource: {
            name: `invoice-${zohoInvoiceNumber}-${currentDatetime}`
          }
        })
        .then((resp: any) => {
          commit('setInvoiceId', resp.result.id)
          return resp
        })
    },
    getInvoice ({ dispatch, getters }): Promise<any> {
      dispatch('setAccessToken')

      return (gapi.client as any).drive.files
        .get({
          fileId: getters.getInvoiceId,
          fields: 'id, name, mimeType, webViewLink, webContentLink'
        })
        .then((resp: any) => resp)
    },
    getInvoiceDoc ({ dispatch, commit, getters }, documentId: string): Promise<any> {
      dispatch('setAccessToken')

      return (gapi.client as any).docs.documents
        .get({
          documentId: documentId || getters.getInvoiceId
        })
        .then((resp: any) => {
          commit('setInvoiceDocResponse', resp.result.body.content)
          return resp.result.body.content
        })
    },
    async updateInvoiceBatchRequest ({ dispatch, getters }, { requests = [] }): Promise<any> {
      dispatch('setAccessToken')

      return (gapi.client as any).docs.documents
        .batchUpdate({
          documentId: getters.getInvoiceId,
          resource: { requests }
        })
        .then(async (resp: any) => {
          await dispatch('getInvoiceDoc')
          return resp
        })
        .catch((response: any) => {
          console.error(response.result.error.message, requests)
          throw Error(response.result.error.message)
        })
    },
    updateInvoice ({ dispatch }, { replaces = [] }: { replaces: any[] }): Promise<any> {
      replaces = replaces.map(repl => ({
        replaceAllText: {
          replaceText: String(repl[1]).toString(),
          containsText: {
            text: repl[0]
          }
        }
      }))

      return dispatch('updateInvoiceBatchRequest', { requests: replaces })
    },
    /**
     * We're going to make multiple requests to:
     * * add N line-item-rows and remove the first one
     * * add content to N line-items
     * * format content of N line-items
     */
    async updateInvoiceTable ({ dispatch }, { lineItems = [] }: { lineItems: ZohoInvoiceLineItem[] }): Promise<any> {
      return dispatch('addInvoiceTableLineItemRows', { lineItems })
        .then(() => dispatch('addInvoiceTableLineItems', { lineItems }))
        .then(() => dispatch('updateInvoiceTableLineItemStyles', { lineItems }))
    },
    async addInvoiceTableLineItemRows ({ dispatch, getters }, { lineItems = [] }: { lineItems: ZohoInvoiceLineItem[] }): Promise<any> {
      const invoiceTable = getters.getInvoiceDocItemsTable

      const inserts: any[] = lineItems.map(() => ({
        insertTableRow: {
          insertBelow: true,
          tableCellLocation: {
            tableStartLocation: {
              segmentId: '',
              index: invoiceTable.startIndex
            },
            rowIndex: 1,
            columnIndex: 0
          }
        }
      }))

      inserts.push({
        deleteTableRow: {
          tableCellLocation: {
            tableStartLocation: {
              segmentId: '',
              index: invoiceTable.startIndex
            },
            rowIndex: 1,
            columnIndex: 0
          }
        }
      })

      return dispatch('updateInvoiceBatchRequest', { requests: inserts })
    },
    async addInvoiceTableLineItems ({ dispatch, getters }, { lineItems = [] }: { lineItems: ZohoInvoiceLineItem[] }): Promise<any> {
      const invoiceTable = getters.getInvoiceDocItemsTable
      const updateRows: any[] = []

      invoiceTable.table.tableRows
        .filter((r: any, i: number) => i >= 1)
        .forEach((r: any, ri: number) => {
          const lineItem = lineItems[ri]
          if (lineItem) {
            const [ date, task, tags ] = lineItem.description.split('\n')

            const cols = [
              date,
              `${lineItem.name} – ${task} \n${tags}`,
              parseTimeFromQty(lineItem.quantity),
              parseCurrency(lineItem.rate),
              parseCurrency(lineItem.item_total)
            ]

            updateRows.push(
              ...cols.map((text, index) => ({
                insertText: {
                  location: {
                    segmentId: '',
                    index: r.tableCells[index].content[0].startIndex
                  },
                  text
                }
              }))
            )
          }
        })

      /**
       * Its important to write these changes backwards because otherwise the indexes would change:
       * @see https://www.youtube.com/watch?v=gjXtWQnBKno
       */
      return dispatch('updateInvoiceBatchRequest', { requests: updateRows.reverse() })
    },
    async updateInvoiceTableLineItemStyles ({ dispatch, getters }): Promise<any> {
      const invoiceTable = getters.getInvoiceDocItemsTable
      const rows: any[] = invoiceTable.table.tableRows
      const updateRows: any[] = []

      rows
        .filter((r: any, i: number) => i >= 1 && i < (rows.length - 3))
        .forEach((r: any) => {
          const { startIndex, endIndex } = r.tableCells[1].content[1]

          updateRows.push({
            updateTextStyle: {
              range: { segmentId: '', startIndex, endIndex },
              textStyle: {
                foregroundColor: {
                  color: {
                    rgbColor: {
                      blue: 0.6,
                      green: 0.6,
                      red: 0.6
                    }
                  }
                }
              },
              fields: 'foregroundColor'
            }
          })
        })

      return dispatch('updateInvoiceBatchRequest', { requests: updateRows })
    }
  }
}

export default module
