import config from 'config'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig: any = config.firebaseConfig
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()

// Auth utils
const auth = firebase.auth()
const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider()

// collection references
const usersCollection = db.collection('users')

// export utils/refs
export { db, auth, GoogleAuthProvider, usersCollection }
