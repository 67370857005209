import { Module } from 'vuex'
import { RootState } from '../'
import { auth, GoogleAuthProvider, usersCollection } from '@/firebase'
import fb from 'firebase'

export type User = fb.User

export interface UserProfile {
  [key: string]: string
}

export interface State {
  authenticated: boolean | string
  user: User | null
  profile: UserProfile
}

const module: Module<State, RootState> = {
  namespaced: true,
  state: {
    authenticated: false,
    user: null,
    profile: {}
  },
  getters: {
    loaded: (state, getters): boolean => !!getters.isLoggedIn &&
          Object.keys(getters.getProfile).length > 0,
    isLoggedIn: (state): boolean => !!state.authenticated,
    getUid: (state): boolean | string => state.authenticated,
    getUser: (state): User | null => state.user,
    getProfile: (state): UserProfile => state.profile,
    getConfig: (state) => (key: string): string | boolean => {
      return state.profile[key] || false
    }
  },
  mutations: {
    authenticate: (state, status: boolean | string) =>
      (state.authenticated = status),
    setUser: (state, user: User) => (state.user = user),
    setProfile: (state, profile) => (state.profile = profile)
  },
  actions: {
    async loginWithGoogle ({ getters, dispatch }): Promise<void | fb.auth.UserCredential> {
      if (getters.isLoggedIn) return

      // Add scopes for Drive import/export here
      GoogleAuthProvider.addScope([
        'https://www.googleapis.com/auth/drive',
        'https://www.googleapis.com/auth/documents'
      ].join(','))

      return auth
        .signInWithPopup(GoogleAuthProvider)
        /**
         * This seems to be the only way to get the current access-token for the GAPI fetched from
         * Firebase-Google-Login. There is another method like which seems anoyingly redundant:
         * @see https://github.com/google/google-api-javascript-client/issues/304
         *
         * In fact the Google-Javascript-SDK isn't build to have refresh-able Oauth-Token so we need to just
         * save it and check if it is expired and reauthorize using the Google-Login.
         */
        .then(e => {
          const { credential, user } = e
          const { accessToken: gapiAccessToken } = (credential as any)
          const gapiAccessTokenExpires = (Date.now() / 1000) + 3600

          return dispatch('updateUserProfile', {
            uid: (user as any).uid,
            gapiAccessToken,
            gapiAccessTokenExpires
          }).then(() => e)
        })
        .catch((e) => console.error('Login failed:', e.message))
    },
    async logout ({ commit, getters }): Promise<void> {
      if (!getters.isLoggedIn) return
      return auth
        .signOut()
        .then(() => {
          commit('authenticate', false)
          commit('setProfile', {})
        })
        .catch((e) => console.error('Logout failed:', e.message))
    },
    async fetchUserProfile ({ dispatch, getters, commit }): Promise<void> {
      const uid: string | boolean = getters.getUid
      if (!uid) return

      const userProfile = await usersCollection
        .doc(uid as string)
        .get()
        .then((e) => {
          if (!e.exists) {
            return usersCollection
              .doc(uid as string)
              .set({}) // Create empty item on first time
              .then(() => dispatch('fetchUserProfile'))
              .then(() => getters.getProfile)
          }

          return e.data()
        })
        .catch((e) => {
          console.error('Error during user/fetchUserProfile:', e.message)
          return {}
        })

      commit('setProfile', userProfile)
    },
    async updateUserProfile ({ dispatch, getters }, payload: any): Promise<void> {
      const uid: string | boolean = payload.uid || getters.getUid
      if (!uid) return

      delete payload.uid

      return usersCollection
        .doc(uid as string)
        .set(payload, { merge: true })
        .then(() => dispatch('fetchUserProfile'))
        .catch((e) => {
          console.error('Cant change value:', e.message, e)
        })
    }
  }
}

export default module
