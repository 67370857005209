import { createApp, App as AppType, ComponentPublicInstance } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './firebase'
import './assets/scss/index.scss'

import mitt from 'mitt'
import loadGapiInsideDOM from './helpers/gapi'

let app: AppType<Element>
let vm: ComponentPublicInstance

auth.onAuthStateChanged(async (user) => {
  if (!app) {
    app = createApp(App)
    app.config.globalProperties.$emitter = mitt()

    app.use(store).use(router)
    vm = app.mount('#app')
  }

  if (user) {
    await loadGapiInsideDOM(
      { },
      [
        { name: 'drive', version: 'v3' },
        { name: 'docs', version: 'v1' }
      ]
    )

    store.commit('user/setUser', user)
    store.commit('user/authenticate', user.uid)
    await store.dispatch('user/fetchUserProfile')
    vm.$emitter.emit('onAuthStateApproved')
  } else {
    router.push('/')
  }
})
